<template>
  <div class="service-category-setting">
    <PageTitle
      title="付款方式設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />

    <section class="section-block">
      <SectionTitle title="付款設定" @edit="otherSettingDialog = true" />

      <BaseElForm :model="config" label-position="left" label-width="250px" testName="payment_setting">
        <BaseElFormItem v-if="useOrderWallet"  label="儲值金支付" prop="enableOrderWallet">
          <BaseElSwitch
            v-model="formData.enableOrderWallet"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
            testName="formData_enableOrderWallet"
            @change="updateOtherSetting()"
          />
        </BaseElFormItem>

        <BaseElFormItem v-if="enableOrderExternalWallet"  :label="`${externalWalletName}支付`" prop="enableOrderExternalWallet">
          <BaseElSwitch
            v-model="formData.enableOrderExternalWallet"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
            testName="formData_enableOrderExternalWallet"
            @change="updateOtherSetting()"
          />
        </BaseElFormItem>

        <BaseElFormItem label="付款期限設置" prop="paymentDeadlineHour">
          {{ config ? config.paymentDeadlineHour || '尚未設定' : '尚未設定' }}
        </BaseElFormItem>

        <BaseElFormItem v-if="useEcount" label="是否開放顧客取消'已付款'之訂單" prop="disableClientCancelPaidOrder">
          <BaseElSwitch
            v-model="formData.disableClientCancelPaidOrder"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
            testName="formData_disableClientCancelPaidOrder"
            @change="updateOtherSetting()"
          />
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <SectionTitle title="" hideTitleStick btn="新增" @edit="openDialog('create')" />

    <section>
      <BaseTable
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />

        <BaseElTableColumn prop="name" label="名稱" align="center">
          <template slot-scope="scope">
            {{ scope.row.displayName || paymentType(scope.row.type) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="enable" label="啟用" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{
                scope.row.enable ? '是' : '否'
              }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'),
                     syncFormData(scope.row),
                     (selectRow = scope.row),
                     findServiceCategory()"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm(), (formData.services = [])"
    >
      <section>
        <BaseElForm
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <BaseElFormItem label="種類" prop="type">
            <p v-if="showOn('update')">{{ paymentTypes[formData.type] }}</p>
            <BaseElSelect
              v-if="showOn('create')"
              v-model="formData.type"
              testName="formData_type"
              placeholder="請選擇"
              @change="paymentTypeChange"
            >
              <BaseElSelectOption
                v-for="option in paymentOptions"
                :key="option"
                :label="paymentType(option)"
                :value="option"
                :testName="option"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="名稱" prop="displayName">
            <BaseElInput
              v-model="formData.displayName"
              testName="formData_displayName"
              placeholder="請輸入付款方式名稱"
              clearable
            />
          </BaseElFormItem>
          <BaseElFormItem label="是否啟用">
            <BaseElSwitch
              v-model="formData.enable"
              testName="formData_enable"
              active-text="是"
              inactive-text="否"
            />
          </BaseElFormItem>

          <!-- LINE Pay -->
          <div v-if="formData.type === 'linepay'">
            <BaseElFormItem label="Channel ID" prop="linePayChannelId">
              <p v-if="showOn('update')">{{ formData.linePayChannelId }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.linePayChannelId"
                testName="formData_linePayChannelId"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="Channel Secret Key" prop="linePayChannelSecretKey">
              <p v-if="showOn('update')">{{ formData.linePayChannelSecretKey }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.linePayChannelSecretKey"
                testName="formData_linePayChannelSecretKey"
                placeholder="請輸入"
              />
            </BaseElFormItem>
          </div>

          <!-- 支付連 -->
          <div v-if="formData.type === 'pchomepay'">
            <BaseElFormItem label="APP ID" prop="pcHomePayAppID">
              <p v-if="showOn('update')">{{ formData.pcHomePayAppID }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.pcHomePayAppID"
                testName="formData_pcHomePayAppID"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="Secret" prop="pcHomePaySecret">
              <p v-if="showOn('update')">{{ formData.pcHomePaySecret }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.pcHomePaySecret"
                testName="formData_pcHomePaySecret"
                placeholder="請輸入"
              />
            </BaseElFormItem>
          </div>

          <!-- 街口 -->
          <!-- <div v-if="formData.type === 'jkos'">
            <BaseElFormItem label="Api-Key" prop="jkosApiKey">
              <p v-if="showOn('update')">{{ formData.jkosApiKey }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.jkosApiKey"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="Sercert-Key" prop="jkosSercertKey">
              <p v-if="showOn('update')">{{ formData.jkosSercertKey }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.jkosSercertKey"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="Store-ID" prop="jkosStoreId">
              <p v-if="showOn('update')">{{ formData.jkosStoreId }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.jkosStoreId"
                placeholder="請輸入"
              />
            </BaseElFormItem>
          </div> -->

          <!-- 藍新 -->
          <div v-if="formData.type === 'newebpay'">
            <BaseElFormItem label="MerchantId" prop="newebpayMerchantId">
              <p v-if="showOn('update')">{{ formData.newebpayMerchantId }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.newebpayMerchantId"
                testName="formData_newebpayMerchantId"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="HashKey" prop="newebpayHashKey">
              <p v-if="showOn('update')">{{ formData.newebpayHashKey }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.newebpayHashKey"
                testName="formData_newebpayHashKey"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="HashIV" prop="newebpayHashIV">
              <p v-if="showOn('update')">{{ formData.newebpayHashIV }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.newebpayHashIV"
                testName="formData_newebpayHashIV"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <!-- <BaseElFormItem label="GateWay" prop="newebpayPayGateWay">
              <BaseElInput v-model="formData.newebpayPayGateWay" />
            </BaseElFormItem> -->
          </div>

          <!-- ATM -->
          <div v-if="formData.type === 'atm'">
            <BaseElFormItem label="銀行代碼" prop="atmBankCode">
              <p v-if="showOn('update')">{{ formData.atmBankCode }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.atmBankCode"
                testName="formData_atmBankCode"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="銀行名稱" prop="atmBankName">
              <p v-if="showOn('update')">{{ formData.atmBankName }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.atmBankName"
                testName="formData_atmBankName"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="分行名稱" prop="atmBranchName">
              <p v-if="showOn('update')">{{ formData.atmBranchName }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.atmBranchName"
                testName="formData_atmBranchName"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="帳戶姓名" prop="atmAccountName">
              <p v-if="showOn('update')">{{ formData.atmAccountName }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.atmAccountName"
                testName="formData_atmAccountName"
                placeholder="請輸入"
              />
            </BaseElFormItem>
            <BaseElFormItem label="帳戶帳號" prop="atmAccountNumber">
              <p v-if="showOn('update')">{{ formData.atmAccountNumber }}</p>
              <BaseElInput
                v-if="showOn('create')"
                v-model="formData.atmAccountNumber"
                testName="formData_atmAccountNumber"
                placeholder="請輸入"
              />
            </BaseElFormItem>
          </div>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(showDialog = false), resetForm(), (formData.services = [])"
        >
          返回
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </BaseElButton>
      </div>
    </el-dialog>

    <!-- 其他設定 Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="otherSettingDialog"
      title="編輯其他設定"
      @close="resetForm"
    >
      <BaseElForm
        ref="otherForm"
        :model="formData"
        :rules="formRules"
        label-position="top"
        testName="other_setting"
        @submit.prevent.native="updateOtherSetting(true)"
      >
        <BaseElFormItem label="付款期限設置" prop="paymentDeadlineHour">
          <BaseElInput
            v-model="formData.paymentDeadlineHour"
            testName="formData_paymentDeadlineHour"
            placeholder="請輸入期限 (單位: 小時)"
          />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="otherSettingDialog = false">取消</BaseElButton>
        <BaseElButton
          type="primary"
          @click="updateOtherSetting(true)"
        >
          儲存
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deletePayment(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { mapGetters } from 'vuex'
import PageTitle from '@/components/Title/PageTitle'
import SectionTitle from '@/components/Title/SectionTitle'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import { paymentConfig } from '@/config/ecommerce'
import { GetConfig, UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import { paymentOptionsConfig } from '@/config/payment'
import {
  CreatePayment,
  FindPayment,
  GetPayment,
  UpdatePayment,
  GetPaymentCount,
  DeletePayment,
} from '@/api/ecommerce/payment'
import DeleteDialog from '@/components/Dialog/DeleteDialog'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, orEmptyText } from '@/utils/helper'
// import * as cssvars from '@/styles/ohbot/_variables.scss'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useExternalWallet } from '@/use/useExternalWallet'

export default {
  name: 'PaymentSetting',
  components: { DeleteDialog, EmptyBlock, PageTitle, SectionTitle },
  setup() {
    const { checkAction } = usePermissions()
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const useOrderWallet = computed(() => checkAction('admin.ecommerceConfig.enableOrderWallet'))
    const enableOrderExternalWallet = computed(() => checkAction('admin.ecommerceConfig.enableOrderExternalWallet'))
    const useEcount = computed(() => checkAction('admin.shopCustomModule.updateEcountClient'))
    onMounted(async () => {
      await getExternalWalletConfig()
    })
    return {
      useOrderWallet,
      enableOrderExternalWallet,
      externalWalletName,
      useEcount,
    }
  },
  data: () => ({
    loading: false,
    isEnable: false,
    uploadDialog: false,
    otherSettingDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    paymentTypes: paymentConfig,
    config: null,

    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    formData: {
      isPublic: true,
      displayName: null,
      order: 100,
      type: '',
      enable: true,
      jkosStoreId: '',
      jkosApiKey: '',
      jkosSercertKey: '',
      newebpayMerchantId: '',
      newebpayHashKey: '',
      newebpayHashIV: '',
      linePayChannelId: '',
      linePayChannelSecretKey: '',
      pcHomePayAppID: '',
      pcHomePaySecret: '',
      // newebpayPayGateWay: '',
      atmBankCode: '',
      atmBankName: '',
      atmBranchName: '',
      atmAccountName: '',
      atmAccountNumber: '',
      paymentDeadlineHour: '',
      enableOrderWallet: false,
      enableOrderExternalWallet: false,
    },

    formRules: {
      displayName: [],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
      type: [noEmptyRules()],
      jkosStoreId: [noEmptyRules()],
      jkosApiKey: [noEmptyRules()],
      jkosSercertKey: [noEmptyRules()],
      newebpayMerchantId: [noEmptyRules()],
      newebpayHashKey: [noEmptyRules()],
      newebpayHashIV: [noEmptyRules()],
      linePayChannelId: [noEmptyRules()],
      linePayChannelSecretKey: [noEmptyRules()],
      pcHomePayAppID: [noEmptyRules()],
      pcHomePaySecret: [noEmptyRules()],
      // newebpayPayGateWay: [noEmptyRules()],
      atmBankCode: [noEmptyRules(), isDigitRules()],
      atmBankName: [noEmptyRules()],
      atmBranchName: [noEmptyRules()],
      atmAccountName: [noEmptyRules()],
      atmAccountNumber: [noEmptyRules(), isDigitRules()],
      paymentDeadlineHour: [isDigitRules(), rangeRules()],
    },
  }),

  computed: {
    ...mapGetters(['shop']),
    paymentOptions () {
      const exclude = ['jkos']
      const types = Object.keys(this.paymentTypes)
      return types.filter(i => !exclude.includes(i))
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增付款方式類別',
        update: '編輯付款方式類別',
      })
    },
    productsList () {
      if (this.formData.services.length === 0) return []
      return extractList('id', this.formData.services)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    showOn (type) {
      return this.dialogType === type
    },
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    paymentType (type) {
      return paymentConfig[type]
    },

    async refresh () {
      this.loading = true
      this.getPayment()
      this.getPaymentCount()
      this.getConfig()
      this.loading = false
    },

    paymentTypeChange (type) {
      this.formData.displayName = null
      this.formData.jkosStoreId = ''
      this.formData.jkosApiKey = ''
      this.formData.jkosSercertKey = ''
      this.formData.newebpayMerchantId = ''
      this.formData.newebpayHashKey = ''
      this.formData.newebpayHashIV = ''
      // this.formData.newebpayPayGateWay = ''
      this.formData.atmBankCode = ''
      this.formData.atmBankName = ''
      this.formData.atmBranchName = ''
      this.formData.atmAccountName = ''
      this.formData.atmAccountNumber = ''
      const paymentType = get(paymentOptionsConfig, type)
      const defaultName = paymentType.name
      if (defaultName) this.formData.displayName = defaultName
    },

    syncFormData (row) {
      this.formData = JSON.parse(JSON.stringify(row))
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      if (this.$refs.form) formUtils.resetForm(this.$refs.form)
      if (this.$refs.otherForm) formUtils.resetForm(this.$refs.otherForm)
      this.formData = {
        isPublic: true,
        order: 100,
        displayName: null,
        type: '',
        enable: true,
        jkosStoreId: '',
        jkosApiKey: '',
        jkosSercertKey: '',
        newebpayMerchantId: '',
        newebpayHashKey: '',
        newebpayHashIV: '',
        // newebpayPayGateWay: '',
        atmBankCode: '',
        atmBankName: '',
        atmBranchName: '',
        atmAccountName: '',
        atmAccountNumber: '',
        paymentDeadlineHour: '',
        enableOrderWallet: false,
        enableOrderExternalWallet: false,
        disableClientCancelPaidOrder: false,
      }
      this.syncConfig()
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createPayment()
      if (type === 'update') this.updatePayment()
      this.showDialog = false
    },

    //= > 取得付款方式
    async getPayment () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetPayment({
        shopId: this.shop,
        start: startIndex,
        limit,
        // enable: this.isEnable,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res.filter(i => i.type !== 'jkos')
    },

    //= > 創建付款方式
    async createPayment () {
      this.loading = true
      const form = this.formData
      const [res, error] = await CreatePayment({
        shopId: this.shop,
        type: form.type,
        displayName: form.displayName || null,
        enable: form.enable,
        jkosStoreId: orEmptyText(form.jkosStoreId),
        jkosApiKey: orEmptyText(form.jkosApiKey),
        jkosSercertKey: orEmptyText(form.jkosSercertKey),
        newebpayMerchantId: orEmptyText(form.newebpayMerchantId),
        newebpayHashKey: orEmptyText(form.newebpayHashKey),
        newebpayHashIV: orEmptyText(form.newebpayHashIV),
        linePayChannelId: orEmptyText(form.linePayChannelId),
        linePayChannelSecretKey: orEmptyText(form.linePayChannelSecretKey),
        pcHomePayAppID: orEmptyText(form.pcHomePayAppID),
        pcHomePaySecret: orEmptyText(form.pcHomePaySecret),
        // newebpayPayGateWay: orEmptyText(form.newebpayPayGateWay),
        atmBankCode: orEmptyText(form.atmBankCode),
        atmBankName: orEmptyText(form.atmBankName),
        atmBranchName: orEmptyText(form.atmBranchName),
        atmAccountName: orEmptyText(form.atmAccountName),
        atmAccountNumber: orEmptyText(form.atmAccountNumber),
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('新增成功')
    },

    async findServiceCategory () {
      const [res, error] = await FindPayment({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log('FindPayment', res)
      this.formData.displayName = res.displayName
      // this.formData.services = res.AppointmentServices.filter(item => ! item.isRemove)
    },

    //= > 刪除付款方式
    async deletePayment () {
      this.loading = true
      const [res, error] = await DeletePayment({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    //= > 更新付款方式
    async updatePayment () {
      this.loading = true
      const form = this.formData
      const [, error] = await UpdatePayment({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
        enable: form.enable,
        displayName: form.displayName || null,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('更新成功')
    },

    //= > 取得服務人員總數
    async getPaymentCount () {
      this.loading = true
      const [res, error] = await GetPaymentCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    syncConfig (res) {
      const config = res || this.config
      this.formData.paymentDeadlineHour = config.paymentDeadlineHour
      this.formData.enableOrderWallet = config.enableOrderWallet
      this.formData.enableOrderExternalWallet = config.enableOrderExternalWallet
      this.formData.disableClientCancelPaidOrder = config.disableClientCancelPaidOrder
    },

    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shop,
      })
      if (error) return this.$message.warning(error)
      this.config = res
      this.syncConfig(res)
    },

    // => 更新其他設定
    async updateOtherSetting (checkInput = false) {
      if (checkInput && !(await formUtils.checkForm(this.$refs.otherForm))) return
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shop,
        enableOrderWallet: this.formData.enableOrderWallet,
        enableOrderExternalWallet: this.formData.enableOrderExternalWallet,
        disableClientCancelPaidOrder: this.formData.disableClientCancelPaidOrder,
        paymentDeadlineHour: !this.formData.paymentDeadlineHour
          ? undefined
          : this.formData.paymentDeadlineHour,
      })

      if (error) this.$message.error(error)
      this.otherSettingDialog = false
      await this.refresh()
      if (!error) return this.$message.success('更新成功！')
    },
  },
}
</script>

<style scoped lang="scss"></style>
